<template>
  <div>
    <div class="comment" :class="[{ shaddow: lastOne }, { corner: hasCorner }]">
      <div class="comment__header mb-[10px]">
        <div class="comment__author">
          <div class="comment__avatar">
            <img :src="avatar" alt="" />
          </div>
          <div class="comment__content">
            <h3 class="comment__title">
              {{ author }}
            </h3>
            <h3 class="comment__title">
              {{ timeAdded }}
            </h3>
          </div>
        </div>
        <div class="comment__actions">
          <Vote
            v-bind="{
              likes: likes,
              isLiked: is_liked,
              isDisliked: is_disliked,
              id: id
            }"
          />
          <el-button
            v-if="userLevel === 2 || userLevel === 3"
            type="text"
            size="mini"
            @click="deleteComment(articleId, id, userId)"
            class="delete_btn"
          >
            Delete
          </el-button>
        </div>
      </div>
      <div>
        <p class="comment__body">
          {{ content }}
        </p>
        <div>
          <div class="reply_btn" @click="toggleDropdown">
            reply
          </div>
          <div v-show="isDropdownOpen">
            <textarea class="textarea" placeholder="e.g. what do you think about this article"
              v-model="getReply" style="width: 800px"></textarea>
            <div class="submit" @click="submitComment(id, articleId, getReply)">
              submit
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="replies.length" class="comment__inner-commment">
      <template v-for="(item, index) in replies" :key="index">
        <NestedComment
          v-bind="{
            articleId: item.articleId,
            avatar: item.image,
            author: item.author,
            id: item.id,
            userId: item.userId,
            timeAdded: item.timeAdded,
            content: item.content,
            replies: item.replies,
            parentId: item.parentId,
            lastOne: index === replies.length - 1,
            hasCorner: replies.length >= 1,
            likes: item.likes,
            change: index
          }"
          @getCommentb="emitGetCommentb"
        />
      </template>
    </div>
  </div>
</template>


<script>
import Vote from "./Vote.vue";
import { ref, nextTick } from 'vue';
import { post } from "@/api";
import { useRoute } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Vote,
  },
  emits: ["getCommentb"],
  setup(props, context) {
    const isDropdownOpen = ref(false);
    const route = useRoute();
    const getReply = ref("");

    const user = ref({});
    const uid = ref(-1);
    const uname = ref("");
    const ucomment = ref("");
    const userLevel = ref(0);

    function loadUser() {
      return post(`/user/logged`).then(res => {
        if (res.res == 1) {
          user.value = res.user;
          uid.value = res.user.uid;
          uname.value = res.user.uname;
          ucomment.value = res.user.commentAuthority;
          userLevel.value = res.user.admin; // set userLevel
        }
      });
    }

    function submitComment(parentId, articleId, replyContent) {
      console.log("Submitting comment:", replyContent);
      if (replyContent && replyContent.trim() !== "") {
        loadUser().then(() => {
          if (uid.value !== -1) {
            const payload = {
              articleId: articleId,
              userId: uid.value,
              content: replyContent.trim(),
              parentId: parentId,
              status: ucomment.value === '1' ? 1 : 0,
            };

            post("/comment/save", payload).then(res => {
              getReply.value = "";
              isDropdownOpen.value = false; // Close the input box
              nextTick(() => {
                context.emit('getCommentb');
                if (ucomment.value !== 1) {
                  console.log("nestedcomment moderation")
                  ElMessageBox.alert("This reply is waiting for moderation", "Notice", {
                    confirmButtonText: "OK",
                  });
                }
              });
            }).catch(err => {
              console.error("Error submitting comment:", err);
            });
          } else {
            ElMessageBox.alert("Please login to reply!", "Notice", {
              confirmButtonText: "OK",
            });
          }
        });
      } else {
        ElMessageBox.alert("Please write something to submit!", "Notice", {
          confirmButtonText: "OK",
        });
      }
    }

    function deleteComment(articleId, commentId, userId) {
      ElMessageBox.confirm("Are you sure you want to delete this comment?", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "warning",
      }).then(() => {
        return post("/comment/delete", { articleId: articleId, commentId: commentId, userId: userId });
      }).then(() => {
        ElMessage.success("Comment deleted successfully.");
        context.emit('getCommentb');
      }).catch(err => {
        console.error("Error deleting comment:", err);
        ElMessage({
          type: "info",
          message: "Delete canceled",
        });
      });
    }

    function emitGetCommentb() {
      context.emit('getCommentb');
    }

    loadUser();

    return {
      isDropdownOpen,
      submitComment,
      getReply,
      deleteComment,
      emitGetCommentb,
      userLevel,
    };
  },
  props: {
    hasCorner: {
      type: Boolean,
      default: false,
    },
    lastOne: {
      type: Boolean,
      default: false,
    },
    avatar: {
      type: String,
      default: "https://picsum.photos/200",
    },
    author: {
      type: String,
      default: "Robin Wieruch",
    },
    timeAdded: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "What is all this then?",
    },
    likes: {
      type: Number,
    },
    id: {
      type: Number,
      default: 0,
    },
    is_liked: {
      type: Boolean,
      default: false,
    },
    is_disliked: {
      type: Boolean,
      default: false,
    },
    replies: {
      type: Array,
      default: () => [],
    },
    parentId: {
      type: Number,
      default: -1,
    },
    userId: {
      type: Number,
      default: -1,
    },
    articleId: {
      type: Number,
      default: -1,
    },
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    }
  }
});
</script>

<style>
.comment {
  padding-top: 20px;
  position: relative;
}
.comment.shaddow {
  box-shadow: calc(20px * -1 - 1px) 0 0 0 #fff;
}
.comment.corner::before {
  left: -21px;
  content: "";
  top: 0;
  height: 30px;
  width: 15px;
  position: absolute;
  border-left: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  border-bottom-left-radius: 8px;
  margin-left: 20px;
}
.comment__author {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.comment__avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 36px;
  align-self: flex-start;
}
.comment__title {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.comment__body {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-left: 20px;
}
.comment__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 20px;
}
.comment__inner-commment {
  margin-left: 20px;
}
.textarea {
  margin-left: 20px;
  width: 100%;
  font-size: 10px;
  padding: 20px 20px;
  background-color: #eff1f5;
  border: none;
  border-radius: 15px;
  min-height: 10px;
  margin-top: 10px;
}
.submit {
  margin-left: 20px;
}
.submit {
  display: block;
  width: 100px;
  height: 20px;
  font-size: 10px;
  color: #fff !important;
  line-height: 20px;
  text-align: center;
  border: none;
  margin: 0 auto;
  border-radius: 15px;
  cursor: pointer;
  font-family: "Poppins-Black";
  background-color: #5473ff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  margin-left: 20px;
}
.submit:hover {
  background-color: #4261f0;
  box-shadow: 0px 2px 20px 0px rgba(59, 93, 245, 0.5);
}
.reply_btn {
  margin-left: 20px;
  font-family: 'Poppins-Bold';
  cursor: pointer;
}
.delete_btn {
  color: black;
  font-size: 10px;
  margin-left: 5px;
  padding: 2px 5px;
}

</style>
